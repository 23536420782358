export default function FormLoader() {
    return <>
        <div className="rounded-md  my-20 max-w-sm h-full">
            <div className="animate-pulse flex space-x-4">
                {/* <div class="rounded-full bg-slate-700 h-10 w-10"></div> */}
                <div className="flex-1 space-y-12 py-1">
                    <div className="space-y-10">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-5 bg-slate-700 rounded col-span-2"></div>
                            <div className="h-5 bg-slate-700 rounded col-span-1"></div>
                        </div>
                        <div className="h-5 bg-slate-700 rounded"></div>
                    </div>
                    <div className="h-5 bg-slate-700 rounded"></div>
                    <div className="h-5 bg-slate-700 rounded"></div>
                    <div className="h-5  bg-slate-700 rounded"></div>
                    <div className="h-5 bg-slate-700 rounded"></div>
                </div>
            </div>
        </div>
    </>
}