const counts = [
    
    {
      "id": "002",
      "label": "EMPLOYEES",
      "number": "160",
      "duration": "2"
    },
    {
      "id": "003",
      "label": "PROJECTS",
      "number": "39",
      "duration": "2"
    }
  ];
  
  export default counts;
  